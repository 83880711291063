import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { PageSpinnerService } from './page-spinner.service';
import { DisplayErrorService } from './display-error.service';
import { Logger, LoggerService } from './logger.service';
import { AuthMethodGenerator } from "../../auth/auth-method-generator";
import { UrlResolverService } from './url-resolver-service';
import { UsersService } from "./rest-services/users.service";
import { Router } from "@angular/router";
import { Auth0Service } from './auth0.service';

export enum HTTPContentType {
  APPLICATION_JSON = 'application/json',
  APPLICATION_OCTET_STREAM = 'application/octet-stream',
  X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded; charset=utf-8',
  TEXT_PLAIN = 'text/plain',
  FORM_DATA = 'multipart/form-data'
}

@Injectable({
  providedIn: 'root'
})
export class HttpSettingInterceptorService implements HttpInterceptor {

  private requestsNum = 0;

  private request: HttpRequest<any>;
  private logger: Logger; components; columnDefs;

  constructor(private urlResolverService: UrlResolverService,
    private displayErrorService: DisplayErrorService,
    private usersService: UsersService,
    private loggerFactory: LoggerService,
    private authService: Auth0Service) {
    this.logger = this.loggerFactory.getLogger("HttpSettingInterceptorService");
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestsNum++;

    const headers = this.generateHeaders(request);
    const apiBaseUrl = this.urlResolverService.getBaseApiUrl(request.url === "logout");
    const apiReq = request.clone({
      url: `${apiBaseUrl}/${request.url}`,
      setHeaders: headers
    });

    const localAssetsRegExp = /^[\.]?(\/)?(assets\/)/;
    if (request.url.startsWith('http') || localAssetsRegExp.test(request.url)) {
      this.request = request;
    } else {
      this.request = apiReq;
    }

    return from(next.handle(this.request).toPromise()
      .catch(async (errorResponse: HttpErrorResponse) => {
        const isAuthenticated = errorResponse?.status !== 0 && errorResponse?.status !== 401 && errorResponse.message == "undefined";

        if (isAuthenticated) {
          this.logger.error('http error %o', {
            error_type: 'Http Error',
            ...errorResponse
          });

          return throwError(() => new Error(errorResponse.message));
        }
        else if (errorResponse?.url?.includes("globalInfo/access") || errorResponse?.url?.includes("authenticate")) {
            return this.logout();
        }
        else {
          await this.tryReLogin();
          return next.handle(this.request).toPromise();
        }
      }));
  }

  /**
   * Check if the body is formData instance,
   * If yes, remove the Content-Type field (the browser will take care of it)
   */
  generateHeaders(request: HttpRequest<any>) {
    let headers = {
      'Content-Type': HTTPContentType.APPLICATION_JSON,
      Accept: HTTPContentType.APPLICATION_JSON,
    }
    if (request && request.body instanceof FormData) {
      delete headers["Content-Type"]
    }
    if (request && request.responseType == "blob") {
      headers = {
        'Content-Type': HTTPContentType.APPLICATION_JSON,
        Accept: HTTPContentType.APPLICATION_OCTET_STREAM,

      }
    }
    return headers;
  }

  tryReLogin(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.authService.reLogin()
        .then(async res => {
          if (res == null)
            reject(await this.logout());
          else
            resolve(res);
        })
        .catch(async err => {
          reject(await this.logout());
        });
    });
  }

  logout(): Promise<any> {
    return this.usersService.logout();
  }
}
